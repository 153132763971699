import React from 'react'
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableRow,
  } from "@/components/ui/table"
import Image from 'next/image'
import Link from 'next/link'
import { Button } from './ui/button'
import { ChevronLeftIcon } from '@radix-ui/react-icons'
  
const MatchesBannerTable = ({data}) => {
  return (
        <div className=' mt-5 lg:mt-0 lg:block relative lg:absolute top-[10%] shadow-lg lg:left-20  2xl:-left-20 bg-gray-800 rounded-md bg-clip-padding backdrop-filter backdrop-blur-lg bg-opacity-20 border border-primary w-full lg:w-2/3 xl:w-1/2 z-40 overflow-hidden transition-all'>
        <Table>
            <TableCaption className="py-2">
                <Link href={`#`}><Button  size="sm">الإطلاع على كافة المباريات <ChevronLeftIcon /></Button></Link>
                </TableCaption>
          
            <TableBody>
                {
                    data.slice(0,5).map((dt,index)=>(
                        <TableRow key={`match_row_${index}`} className="text-center ">
                           
                            <TableCell>
                                <div className='flex flex-col gap-4'>
                                    <div className='flex flex-row gap-3 justify-start items-center'>
                                        <img src={`https://picsum.photos/25/25?random=${index}1`} className='rounded-full' height={25} width={25}  />
                                        <span className='text-white'>{dt.team1}</span>
                                    </div>
                                    <div className='flex flex-row gap-3 justify-start items-center'>
                                        <img src={`https://picsum.photos/25/25?random=${index}2`} className='rounded-full' height={25} width={25}/>
                                        <span className='text-white'>{dt.team2}</span>
                                    </div>
                                </div>
                            </TableCell>
                            
                            <TableCell className="text-center text-white dark:text-white">
                                <div className='flex flex-col'>
                                    <span>{dt.date}</span>
                                    <span>{dt.time}</span>
                                </div>
                            </TableCell>
                            <TableCell className="text-center text-white dark:text-white">{dt.venue}</TableCell>
                        </TableRow>
                    ))
                }
            </TableBody>
    </Table>

        </div>
      
    
  )
}

export default MatchesBannerTable