'use client'
import React from 'react'
import {
    Carousel,
    CarouselContent,
    CarouselItem,
  } from "@/components/ui/carousel";
import Autoplay from "embla-carousel-autoplay"  
import Link from 'next/link';

const HomePageSection8 = ({articles}) => {
  return (
    <Carousel className="z-30 relative" plugins={[
        Autoplay({
        delay: 5000,
        }),]}  opts={{direction: "rtl"}}>
        <CarouselContent>
        {articles?.map((ci) => (
            <CarouselItem key={ci.id}>
                <div className="relative rounded-md overflow-hidden aspect-[3/4] w-full"> 
                  <Link href={`/Article/${ci.MainInfo.slug}`}>
                      <img  
                        src={`https://cloud.time4.news/mid/${ci.MainImage.filepath}`} 
                        layout="fill" 
                        alt={ci.MainImage.alt}  
                        className="w-full h-full object-cover rounded-md"/>
                      <h3 className=' absolute text-center bottom-0 right-0 h-full w-full p-3 bg-gradient-to-tr from-primary/50 to-transparent z-10 flex justify-center items-end text-2xl text-white font-bold'>{ci.MainInfo.title}</h3>
                  </Link>
              
                </div>
            </CarouselItem>
        ))}
        </CarouselContent>
    
    </Carousel>
  )
}

export default HomePageSection8