'use client'
import { useEffect, useState } from 'react';
import { Input } from './ui/input';
import { voteOnPoll } from '@/lib/Api/MainData';

const Poll = ({ poll }) => {
  const [mainPoll, setMainPoll] = useState(null);
  const [totalVotes, setTotalVotes] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [userDetails, setUserDetails] = useState({
    name: '',
    email: '',
    whatsapp: '',
  });
  const [autoSubmit, setAutoSubmit] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    if (selectedOption !== null && userDetails.name && userDetails.email && userDetails.whatsapp && !submitted) {
      localStorage.setItem('Time4GamesPollId', poll.id);
      localStorage.setItem('TIme4GamesUserDetails', JSON.stringify(userDetails));

      await voteOnPoll(poll.id, selectedOption, userDetails.name, userDetails.email, userDetails.whatsapp).then((new_poll)=>{
        setMainPoll(new_poll)
        setTotalVotes(new_poll.Options.reduce((sum, option) => sum + option.votes_nr, 0))
      });

      setSubmitted(true);
    }
  };

  useEffect(() => {
    setMainPoll(poll);
    setTotalVotes(poll.Options.reduce((sum, option) => sum + option.votes_nr, 0));

    // Check if pollId and userDetails are stored in localStorage
    const storedPollId = localStorage.getItem('Time4GamesPollId');
    const storedUserDetails = localStorage.getItem('TIme4GamesUserDetails');

    if (storedPollId === poll.id) {
      setSubmitted(true);
    }
    if (storedUserDetails) {
      const details = JSON.parse(storedUserDetails);
      setUserDetails(details);
      setAutoSubmit(true);  // Automatically submit if userDetails are stored
    }
  }, [poll]);

  useEffect(() => {
    if (autoSubmit && selectedOption !== null) {
      if(!submitted){
        handleSubmit();  
      }
    }
  }, [autoSubmit, selectedOption]);

  return (
    <div className="w-full px-3 mt-10 sticky top-10">
      <h2 className="text-2xl font-bold mb-4">{mainPoll?.Question}</h2>
      {mainPoll?.Options.map((option, index) => (
        <div 
          key={`poll_index_${option.id}`} 
          className={`block items-center mb-4 w-full bg-[url('/pattern2.png')] bg-cover rounded-md bg-clip-padding backdrop-filter backdrop-blur-lg bg-opacity-20 border ${!submitted  && 'cursor-pointer'} hover:bg-opacity-50 transition-all duration-300 border-primary ${!submitted && selectedOption !== null && selectedOption !== option.id && 'opacity-20'}`}
          onClick={() =>  setSelectedOption(option.id)}
        >
          <input
            type="radio"
            id={`option-${index}`}
            name="poll"
            value={option.id}
            checked={selectedOption === option.id}
            onChange={() => setSelectedOption(option.id)}
            className="mr-2 hidden"
          />
          <label htmlFor={`option-${index}`} className={`text-lg w-full h-full ${!submitted && 'cursor-pointer'} p-3 block`}>
            <div className='flex flex-row justify-between'>
              <h5 className='font-bold'>{option.text}</h5>
              <div>
                {submitted && <span className='block mt-3 text-xs text-primary font-bold'>
                  {totalVotes !== 0 ? ((option.votes_nr / totalVotes) * 100).toFixed(2) : 0 }%
                </span>}
              </div>
            </div>
            {submitted && <span style={{width:`${(option.votes_nr / totalVotes) * 100 }%`}} className={`inline-block bg-primary h-1`}></span>}
          </label>
        </div>
      ))}

      {selectedOption !== null && !submitted && !autoSubmit && (
        <div className="mt-6">
          <div className="mb-4">
            <Input
              type="text"
              id="name"
              name="name"
              placeholder="الاسم"
              value={userDetails.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4">
            <Input
              placeholder="البريد الإلكتروني"
              type="email"
              id="email"
              name="email"
              value={userDetails.email}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4">
            <Input
              placeholder="واتساب"
              type="text"
              id="whatsapp"
              name="whatsapp"
              value={userDetails.whatsapp}
              onChange={handleInputChange}
            />
          </div>
          <button 
            onClick={handleSubmit} 
            className="mt-4 bg-primary text-white p-2 rounded-md"
          >
            تصويت
          </button>
        </div>
      )}
    </div>
  );
};

export default Poll;
