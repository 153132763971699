export const CompotitorSchema = `
id
Name{
    En
    Ar
}
isTeam
Country{
    Name{
        En
        Ar
    }
}
MainImage{
    FilePath
}
`