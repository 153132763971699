import React from 'react'
import { Card } from './ui/card'
import {
    Carousel,
    CarouselContent,
    CarouselItem,
  } from "@/components/ui/carousel";
import Autoplay from "embla-carousel-autoplay"  

const sportsNewsTitles = [
    "ريال مدريد يتأهل إلى نهائي دوري أبطال أوروبا",
    "برشلونة يحقق فوزاً ساحقاً على فالنسيا",
    "ليفربول يتعادل مع مانشستر سيتي في مباراة مثيرة",
    "منتخب مصر يفوز بكأس الأمم الأفريقية",
    "يوفنتوس يتعاقد مع نجم الوسط الفرنسي",
    "الهلال يتصدر الدوري السعودي بفوز كبير",
    "النصر يهزم الاتحاد في كلاسيكو الكرة السعودية",
    "بايرن ميونيخ يحتفظ بلقب الدوري الألماني",
    "تشيلسي يتعاقد مع مهاجم دولي جديد",
    "الزمالك يفوز بالدوري المصري بعد غياب طويل"
  ];

  
const BannerLatestNews = () => {
  return (
    <div className='hidden xl:block absolute z-40 top-10 -right-20  w-1/4 h-[25vh] p-5'>
        <Carousel plugins={[Autoplay({delay: 3000})]} opts={{align: "start"}} orientation="vertical">
            <CarouselContent className="h-[25vh] w-full p-3">
                {
                    sportsNewsTitles.map((snt,index)=>(
                        <CarouselItem className="basis-1/2" key={`snt_index`}>
                            <Card className=" h-full p-4 bg-gray-800 rounded-md bg-clip-padding backdrop-filter backdrop-blur-lg bg-opacity-20 border-b border-l border-primary">
                                <h3 className='font-bold text-white'>{snt}</h3>
                            </Card>
                        </CarouselItem>
                    ))
                }            
            </CarouselContent>
        </Carousel>
    </div>
    

  )
}

export default BannerLatestNews