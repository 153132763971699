export const CategoryGQLSchema = `
id
MainInfo{
    title
    slug
}
seo{
    metaDescription
    metaKeywords
}
`