import { CategoryGQLSchema } from "./CategorySchema";

export const ArticleGQlSchema = `
id
MainInfo{
    title
    slug
    author{
        exist
        author_name
    }
    category{
        ${CategoryGQLSchema}
    }
    source{
        exist
        name
        link
    }
}
MainImage{
    alt
    filepath
}
content
seo{
    keywords
    description
}
Attachments{
    type
    content
    alt
}
infos{
    views
    shortcut
    createdAt
}
`