import { CompotitorSchema } from "./Compotitors";

export const SportEvent = `
id
Compitiors{
    Home{
        ${CompotitorSchema}
    }
    Away{
        ${CompotitorSchema}
    }
}
Venue
StartTime
isLive
Ended
Score{
    Home
    Away
}
State{
    Winner
}
`