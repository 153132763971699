import(/* webpackMode: "eager", webpackExports: ["default"] */ "/time4news/frontend/components/Banner.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/time4news/frontend/components/HomePageSection8.jsx");
;
import(/* webpackMode: "eager" */ "/time4news/frontend/components/Poll.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["Carousel","CarouselContent","CarouselItem"] */ "/time4news/frontend/components/ui/carousel.jsx");
;
import(/* webpackMode: "eager" */ "/time4news/frontend/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/time4news/frontend/node_modules/next/dist/client/link.js");
