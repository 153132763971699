import { GLOBALAPI, TIME4GAMESAPI } from "../constants";
import { ArticleGQlSchema } from "../Schema/Article";
import { ArticleCategoryTag } from "../Schema/ArticleCategoryTag";
import { BannerAdGQLSchema } from "../Schema/BannerAd";
import { CategoryGQLSchema } from "../Schema/CategorySchema";
import { POllGQLSchema } from "../Schema/Poll";
import { PortfolioGQLSchema } from "../Schema/Portfolio";
import { SportEvent } from "../Schema/SportEvent";

export async function getArticleCategoryBySlug(slug){
    const res = await fetch(GLOBALAPI,{
        method:"POST",
        cache:"no-store",
        headers:{
          "Content-Type": "application/json"
        },
        body:JSON.stringify({
            query:`
              query{
                GetArticleCategoryBySlug(slug:"${slug}"){
                  ${CategoryGQLSchema}
                }
              }
            `
        })
    })
    const data = await res.json()
    return data.data.GetArticleCategoryBySlug
}

export async function getArticleBySlug(slug){
    const res = await fetch(GLOBALAPI,{
        method:"POST",
        cache:"no-store",
        headers:{
          "Content-Type": "application/json"
        },
        body:JSON.stringify({
            query:`
              query{
                GetArticleBySlug(slug:"${slug}"){
                  ${ArticleGQlSchema}
                }
              }
            `
        })
    })
    const data = await res.json()
    return data.data.GetArticleBySlug
}

export async function getPortfolioRandomly(count){
    const res = await fetch(GLOBALAPI,{
      method:"POST",
      cache:"no-store",
      headers:{
        "Content-Type": "application/json"
      },
      body:JSON.stringify({
          query:`
            query{
              GetPortfolioRandomly(count:${count}){
                ${PortfolioGQLSchema}
              }
            }
          `
      })
  })
  const data = await res.json()
  return data.data.GetPortfolioRandomly
}

export async function getArticleByShortcut(slug){
      const res = await fetch(GLOBALAPI,{
          method:"POST",
          cache:"no-store",
          headers:{
            "Content-Type": "application/json"
          },
          body:JSON.stringify({
              query:`
                query{
                  GetArticleByShortcut(slug:"${slug}"){
                    ${ArticleGQlSchema}
                  }
                }
              `
          })
      })
      const data = await res.json()
      return data.data.GetArticleByShortcut
}

export async function getArticleByCategory(category,count,offset){
    const res = await fetch(GLOBALAPI,{
        method:"POST",
        cache:"no-store",
        headers:{
          "Content-Type": "application/json"
        },
        body:JSON.stringify({
            query:`
              query{
                GetArticleByCateogries(id:"${category}",count:${count},offset:${offset},sort:"-1"){
                  ${ArticleGQlSchema}
                }
              }
            `
        })
    })
    const data = await res.json()
    return data.data.GetArticleByCateogries
}

export async function getLatestArticles(count=8){
    const res = await fetch(GLOBALAPI,{
        method:"POST",
        cache:"no-store",
        headers:{
          "Content-Type": "application/json"
        },
        body:JSON.stringify({
            query:`
              query{
                GetLatestArticle(count:${count}){
                  ${ArticleGQlSchema}
                }
              }
            `
        })
    })
    const data = await res.json()
    return data.data.GetLatestArticle
}

export async function getRandomArticlesWithCommonKeywords(id){
    const res = await fetch(GLOBALAPI,{
        method:"POST",
        cache:"no-store",
        headers:{
          "Content-Type": "application/json"
        },
        body:JSON.stringify({
            query:`
              query{
                GetRandomArticlesWithCommonKeywords(id:"${id}"){
                  ${ArticleGQlSchema}
                }
              }
            `
        })
    })
    const data = await res.json()
    return data.data.GetRandomArticlesWithCommonKeywords
}

export async function getRandomArticlesByCategory(id){
    const res = await fetch(GLOBALAPI,{
        method:"POST",
        cache:"no-store",
        headers:{
          "Content-Type": "application/json"
        },
        body:JSON.stringify({
            query:`
              query{
                GetRandomArticlesByCategory(id:"${id}"){
                  ${ArticleGQlSchema}
                }
              }
            `
        })
    })
    const data = await res.json()
    return data.data.GetRandomArticlesByCategory
}

export async function getArticleCategoryTag(id){
  const res = await fetch(GLOBALAPI,{
    method:"POST",
    cache:"no-store",
    headers:{
      "Content-Type": "application/json"
    },
    body:JSON.stringify({
        query:`
          query{
            GetArticleCategoryTag(id:"${id}"){
              ${ArticleCategoryTag}
            }
          }
        `
    })
  })
  const data = await res.json()
  return data.data.GetArticleCategoryTag
}

export async function getBannerAd(){
  const res = await fetch(GLOBALAPI,{
    method:"POST",
    cache:"no-store",
    headers:{
      "Content-Type": "application/json"
    },
    body:JSON.stringify({
        query:`
          query{
            GetBannerAd{
              ${BannerAdGQLSchema}
            }
          }
        `
    })
  })
  const data = await res.json()
  return data.data.GetBannerAd
}

export async function searchArticles(query,limit,offset){
  const res = await fetch(GLOBALAPI,{
    method:"POST",
    cache:"no-store",
    headers:{
      "Content-Type": "application/json"
    },
    body:JSON.stringify({
        query:`
          query{
            SearchArticles(query:"${query}",limit:${limit},offset:${offset}){
              ${ArticleGQlSchema}
            }
          }
        `
    })
  })
  const data = await res.json()
  return data.data.SearchArticles
}

export async function getRandomArticlesByEXCCategory(id,count){
  const res = await fetch(GLOBALAPI,{
    method:"POST",
    cache:"no-store",
    headers:{
      "Content-Type": "application/json"
    },
    body:JSON.stringify({
        query:`
          query{
            GetRandomArticlesByEXCCategory(id:"${id}",count:${count}){
              ${ArticleGQlSchema}
            }
          }
        `
    })
  })
  const data = await res.json()
  return data.data.GetRandomArticlesByEXCCategory
}


export async function getTime4GamesSportEvents(){
  const res = await fetch(TIME4GAMESAPI,{
    method:"POST",
    cache:"no-store",
    headers:{
      "Content-Type": "application/json"
    },
    body:JSON.stringify({
        query:`
          query{
            GetTodaySportEvent{
              ${SportEvent}
            }
          }
        `
    })
  })
  const data = await res.json()
  return data.data.GetTodaySportEvent

}

export async function getActivePoll(){
  const res = await fetch(GLOBALAPI,{
    method:"POST",
    cache:"no-store",
    headers:{
      "Content-Type": "application/json"
    },
    body:JSON.stringify({
        query:`
          query{
            GetActivePoll{
              ${POllGQLSchema}
            }
          }
        `
    })
  })
  const data = await res.json()
  return data.data.GetActivePoll
}

export async function voteOnPoll(id,o_id,name,email,whatsapp){
  const res = await fetch(GLOBALAPI,{
    method:"POST",
    cache:"no-store",
    headers:{
      "Content-Type": "application/json"
    },
    body:JSON.stringify({
        query:`
          mutation{
            VotePoll(id:"${id}",o_id:"${o_id}",name:"${name}",email:"${email}",whatsapp:"${whatsapp}"){
              ${POllGQLSchema}
            }
          }
        `
    })
  })

  const data = await res.json()
  return data.data.VotePoll

}

export async function getArticlesCount(){
  const res = await fetch(GLOBALAPI,{
    method:"POST",
    cache:'no-store',
    headers:{
      "Content-Type":"application/json"
    },
    body:JSON.stringify({
      query:`
        query{
          GetArticlesCount
        }
      `
    })
  })

  const data = await res.json()
  return data.data.GetArticlesCount
}

export async function getAllArticles(count,offset){
  const res =  await fetch(GLOBALAPI,{
    method:"POST",
    cache:'no-store',
    headers:{
      "Content-Type":"application/json"
    },
    body:JSON.stringify({
      query:`
        query{
          GetAllArticles(sort:"-1",count:${count},offset:${offset}){
            id
            MainInfo{
              slug
            }
            infos{
              createdAt
            }
          }
        }
      `
    })
  })

  const data = await res.json()
  return data.data.GetAllArticles
}