import { CategoryGQLSchema } from "./CategorySchema";

export const PortfolioGQLSchema = `
id
MainInfo{
    title
    names {
        ar
        en
    }
    slug
    startDate {
        isBirth
        date
    }
    endDate {
        isDeath
        date
    }
    startPlace {
        isBirth
        place
    }
    owners
    author {
        exist
        author_name
    }
    category {
        ${CategoryGQLSchema}
    }
    source {
        exist
        name
        link
    }
}
MainImage{
    alt
    filepath
}
content{
    id
    title
    text
}
seo{
    keywords
    description
}
options{
    published
    done
}
infos{
    views
    shortcut
    createdAt
    updateDate
}
`;
