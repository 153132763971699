export const GLOBALAPI = `https://api.time4.news/api`
export const CLOUDIMAGE = `http://cloud.time4.news/`
export const TIME4GAMESAPI = `https://api.time4.games/api/global`

export function formatDateArabic(date) {
    const days = ['الأحد', 'الإثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت'];
    const months = ['كانون الثاني', 'شباط', 'آذار', 'نيسان', 'أيار', 'حزيران', 'تموز', 'آب', 'أيلول', 'تشرين الأول', 'تشرين الثاني', 'كانون الأول'];

    const dayName = days[date.getDay()];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${dayName}، ${day} ${month} ${year}`;
}

export const SLUDID = {
    "كرة_القدم": "640d05eed73c8ee671119e31",
    "كرة_السلة": "64217db03d4bb54f989ad8c5",
    "كرة_اليد": "64217df43d4bb54f989ad8ca",
    "التنس": "64217e063d4bb54f989ad8cf",
    "كرة_الطائرة": "6426e36f5e1c9e51a95f7de4",
    "فنون_قتالية": "6426e3785e1c9e51a95f7de9",
    "رياضات_مائية": "6426e3815e1c9e51a95f7dee",
    "كرة_الطاولة": "6426e3ad5e1c9e51a95f7df3",
    "رياضات_أمريكية": "6426e3be5e1c9e51a95f7df8",
    "رياضات_متنوعة": "66b7b7fa5de48596a188b456",
    "موتو_سبور": "66b7b8085de48596a188b45b",
    "لاعبون_وأحداث": "66b85a165de48596a188b462"
  }
  