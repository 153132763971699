'use client'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel";
import Image from "next/image";
import Autoplay from "embla-carousel-autoplay"  
import MatchesBannerTable from "./MatchesBannerTable";
import BannerLatestNews from "./BannerLatestNews";
import Link from "next/link";
import { useEffect, useState } from 'react';

// Shuffle function
function shuffleArray(array) {
  return array.sort(() => Math.random() - 0.5);
}

const Banner = ({ images }) => {
  const [shuffledImages, setShuffledImages] = useState([]);

  // Shuffle images on component mount
  useEffect(() => {
    setShuffledImages(shuffleArray([...images])); // shuffle and set state
  }, [images]);

  return (
    <div className="relative my-10">
      <Carousel
        className="z-30 relative overflow-y-hidden aspect-[21/7]"
        plugins={[
          Autoplay({
            delay: 5000,
          }),
        ]}
        opts={{ direction: "rtl" }}
      >
        <CarouselContent>
          {shuffledImages.map((ci) => (
            <CarouselItem key={ci.id}>
              {ci.Link ? (
                <Link
                  target="_blank"
                  href={ci.Link}
                  className="relative rounded-md overflow-hidden aspect-[21/7] w-full"
                >
                  <img
                    src={`https://cloud.time4.news/${ci.Image.FilePath}`}
                    alt={ci.Image.Alt}
                    className="w-full h-full object-cover rounded-md"
                  />
                </Link>
              ) : (
                <div className="relative rounded-md overflow-hidden aspect-[21/7] w-full">
                  <img
                    src={`https://cloud.time4.news/${ci.Image.FilePath}`}
                    alt={ci.Image.Alt}
                    className="w-full h-full object-cover rounded-md"
                  />
                </div>
              )}
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
      {/*<MatchesBannerTable data={matches}/> */ }
    </div>
  );
};

export default Banner;
